import './rightPartGraduate.css';

import { Markup } from 'interweave';

export default function RightPartGraduate(props) {

    return (
        <div className="RightPartGraduate">
            <Markup content={props.introP} />
            <div className='imgs'>
                <img className='img-right' src={props.image1} width="" height="" alt="EPD right" />
                <img className='img-left' src={props.image2} width="" height="" alt="EPD left" />
            </div>
            <div className='quote'>
                <p>{props.quote}</p>
            </div>
        </div>
    );
}

// props :
// introP - image1 - image2 - quote - textP