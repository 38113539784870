import './bodyPart.css';

import MapImg from '../../../assets/images/about/about-1.png';

import { IoMdQuote } from "react-icons/io";

export default function BodyPart() {
  return (
    <div className="BodyPart">
      <img src={MapImg} width='' height='' alt='Map' />
      <div className='quote'>
        <IoMdQuote size={32} />
        <p>Improve the lives of youth in poor & vulnerable communities through design, entrepreneurship & technology. Our  studio is  Place-based focused for contextual uniqueness at the local and global level.</p>
      </div>
    </div>
  );
}