import './leftPartBlog.css';

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Link } from 'react-router-dom';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

export default function LeftPartBlog(props) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className='LeftPartBlog'>
            <div className='part-1'>
                <h4>{props.blogger}</h4>
                <h6>{props.publishDate}</h6>
                <p onClick={handleShow}>Share</p>
            </div>
            <div className='part-2'>
                <h6>Featured Blogs</h6>
                <ul>
                    {props.featuredBlog.map((data) => (
                        <li key={data.id}>
                            <Link to={`/blog/${data.id}`}>
                                {data.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <Modal
                className='social-share'
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p>Share the blog in social medias :</p>
                    <div className='d-flex justify-content-between align-items-center'>
                        <FacebookShareButton url={props.sharedURL}>
                            <FacebookIcon size={36} round={false} />
                        </FacebookShareButton>
                        <EmailShareButton url={props.sharedURL}>
                            <EmailIcon size={36} round={false} />
                        </EmailShareButton>
                        <LinkedinShareButton url={props.sharedURL}>
                            <LinkedinIcon size={36} round={false} />
                        </LinkedinShareButton>
                        <RedditShareButton url={props.sharedURL}>
                            <RedditIcon size={36} round={false} />
                        </RedditShareButton>
                        <TelegramShareButton url={props.sharedURL}>
                            <TelegramIcon size={36} round={false} />
                        </TelegramShareButton>
                        <TwitterShareButton url={props.sharedURL}>
                            <TwitterIcon size={36} round={false} />
                        </TwitterShareButton>
                        <WhatsappShareButton url={props.sharedURL}>
                            <WhatsappIcon size={36} round={false} />
                        </WhatsappShareButton>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

// props :
// blogger - publishDate - featuredBlog - sharedURL