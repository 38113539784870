import './mentorsTable.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaLinkedinIn, FaEnvelope } from "react-icons/fa";

import { PaginatedList } from 'react-paginated-list';

export default function MentorsTable(props) {

  return (
    <div className="MentorsTable">
      <p>{props.introP}</p>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <ul className='list-mentors'>
            <PaginatedList
              list={props.mentors}
              itemsPerPage={5}
              nextText='Next'
              prevText='Prev.'
              loopAround={false}
              displayNumbers={true}
              displayRange={1}
              isLoading={false}
              renderList={(list) => (
                <>
                  {list.map((data) => {
                    return (
                      <li key={data.id} className='item-mentor'>
                        <Row>
                          <Col xs={2} md={2} lg={2} className='part-1'>
                            <img src={data.image} width="" height="" alt='Mentor' />
                          </Col>
                          <Col xs={10} md={10} lg={10}>
                            <Row>
                              <Col xs={12} md={12} lg={12} className='part-2'>
                                <h6> {data.name} </h6>
                                <p> {data.profile} </p>
                                <div className='social d-inline-flex'>
                                  <a href={data.linkedin} target='_blanck'><FaLinkedinIn size={15} /></a>
                                  <a href={`mailto:${data.email}`}><FaEnvelope size={15} /></a>
                                </div>
                              </Col>
                              <Col xs={12} md={12} lg={12} className='part-3 d-flex justify-content-end align-items-end'>
                                <p> {data.location} </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </li>
                    );
                  })}
                </>
              )}
            />
          </ul>
        </Col>
      </Row>
    </div>
  );
}

// props :
// introP - mentors