import './gateway.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useParams as params } from "react-router-dom";
import { posts } from "../../constants/posts";
import { mentors } from "../../constants/mentors";

// Page parts
import TopPart from './topPartGateway/topPartGateway';
import LeftPart from './leftPartGateway/leftPartGateway';
import RightPart from './rightPartGateway/rightPartGateway';
import BottomPart from './bottomPartGateway/bottomPartGateway';
import MentorsTable from './mentorsTable/mentorsTable';

import Header from '../../components/layouts/header/header';

export default function Gateway() {

    const { post_id } = params();
    const postFiltered_next = posts.find(post => String(post.id) === String(Number(post_id) + 1));
    const postFiltered = posts.find(post => String(post.id) === post_id);

    return (
        <div className="Gateway">
            <Header />
            <TopPart
                image={postFiltered.image}
                title={postFiltered.title}
            />
            {postFiltered.id === posts.length ?
                <div className='container-list d-flex justify-content-center align-items-center'>
                    <MentorsTable
                        introP={postFiltered.more.right.introP}
                        mentors={mentors}
                    />
                </div>
                : <div>
                    <Row>
                        <Col xs={12} md={12} lg={3}>
                            <LeftPart
                                trainer={postFiltered.more.left.trainer}
                                cv={postFiltered}
                                sdgs={postFiltered?.more?.left?.sdgs}
                                modalTitle={postFiltered.more.left.cv.title}
                                goalsList={postFiltered.more.left.cv.goals}
                                outlineList={postFiltered.more.left.cv.outline}
                                standardsList={postFiltered.more.left.cv.standards}
                            />
                        </Col>
                        <Col xs={12} md={12} lg={9}>
                            <RightPart
                                introP={postFiltered.more.right.introP}
                                image1={postFiltered.more.right.image1}
                                image2={postFiltered.more.right.image2}
                                quote={postFiltered.more.right.quote}
                                textP={postFiltered.more.right.textP}
                            />
                        </Col>
                    </Row>
                    <BottomPart
                        idNext={postFiltered_next.id}
                        imageNext={postFiltered_next.image}
                        titleNext={postFiltered_next.title}
                    />
                </div>
            }
        </div>
    );
}