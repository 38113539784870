// Items files importations
import mentor1 from '../assets/images/mentors/mentor-1.jpg';
import mentor2 from '../assets/images/mentors/mentor-2.jpg';
import mentor3 from '../assets/images/mentors/mentor-3.jpg';
import mentor4 from '../assets/images/mentors/mentor-4.jpg';
import mentor5 from '../assets/images/mentors/mentor-5.jpg';

export const mentors = [
    {
        id: 1,
        image: mentor1,
        name: "Fas lebbie",
        profile: "Founder, Design, Entrepreneurship and Innovation Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>We are led by our Founder Fas Lebbie, an entrepreneur & designer born and raised in Sierra Leone before immigrating to America. He has the experience, education, and purpose to disrupt the natural resource category globally. <span>Root Diamonds</span> has a unique combination of knowledge and passion for the diamond industry with a deep understanding of branding and experiential retail.</p><p>In his Book- <span>Souvenir Of My Awakening</span> recently publish- “ Having been born in the Kono District of Sierra Leone, I've seen first-hand the depravity, corruption and poor conditions the people are living in.</p><p>Currently a Teaching Fellow and Ph.D. Student at <span>Carnegie Mellon University</span>, Fas' research aims at empowering local and natural resource ecosystems and designing sustainable product/service systems (S.PSS) - Specifically Distributed Natural Resources Systems.</p>",
        education: "<ul><li>Currently studying for PhD Transition Design - Carnegie Mellon University (Present).</li><li>MS in Strategic Design and Management - Parsons School of Design: The New School (2021).</li><li>Fellowship Program in Impact Entrepreneurship - Parsons School of Design (2020).</li><li>Certificate in UX Design AR and VR - NYU Tandon School of Engineering (2020).</li><li>BA in Entrepreneurship/Entrepreneurial Studies and International Studies with Concentration in Pre Med - University of Utah (2018).</li></ul>"
    },
    {
        id: 2,
        image: mentor2,
        name: "Maarten De Witte",
        profile: "Diamond Cutting & Grading Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Maarten de Witte has been intimately involved with cutting gem diamonds for 50 years. Having learned his trade at the American School of Diamond Cutting, he eventually became its Director. He is renowned as the Diamond Wizard™.</p><p>Maarten's experience ranges from the solitary workbench to the global diamond industry. He was instrumental in building the Hearts On Fire Company, from initial start-up to international brand. He is devoted to excellence as a craftsman, innovator, and teacher - in wholesale, retail, branding, training, research and/or development of new cutting styles.</p>",
        education: "<ul><li>Graduate Gemologist - Gemological Institute of America (2003).</li><li>MS in Minerals Geography - University of Illinois (1989).</li><li>BA in African History - University of Illinois (1986).</li><li>Certified Gemologist - American Gem Society (1984).</li></ul>"
    },
    {
        id: 3,
        image: mentor3,
        name: "Mickey Brookshire",
        profile: "Diamond Cutting & Grading Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Mickey began cutting diamonds in 1972. From his small cutting shop in Scottsdale, Arizona he manufactures rough and specializes in repairing damaged stones and recutting older styles to modern proportions. He buys secondhand finished stones as well which are often recut and resold to the trade.</p><p>From 1978 to the present he has been dealing in rough and mining alluvial deposits in the Mano River Union Countries of Sierra Leone, Liberia and Guinea.</p><p>In his efforts to build a more environmentally conscientious and sustainable business in West Africa he now focuses his attention on low-impact dredging and customizing the cutting process. By leaving a small footprint on the environment and maximizing the beauty and value of the rough he has been able to better give back to the people and the communities that have been instrumental to his growth.</p><p>He now mines select alluvial sites and produces signature cuts that respect the individuality of each piece of rough.</p>",
        education: "<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia in nunc, nec et id viverra tempor consectetur faucibus. Molestie nibh integer diam ac condimentum integer tellus turpis. Mi, varius ullamcorper ultrices arcu sit vel sit. Duis a risus mole.</li></ul>"
    },
    {
        id: 4,
        image: mentor4,
        name: "Ty Brooks",
        profile: "Diamond Cutting Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia in nunc, nec et id viverra tempor consectetur faucibus. Molestie nibh integer diam ac condimentum integer tellus turpis. Mi, varius ullamcorper ultrices arcu sit vel sit. Duis a risus molestie dictum vel sit. Tortor fames turpis sed rhoncus ac. Enim, rhoncus, sit quam nunc in lectus. Vel cras libero odio leo nibh phasellus. Scelerisque leo lacus velit et eget a amet at id. At tincidunt adipiscing commodo neque tristique. Odio vitae amet, tristique pulvinar. Tristique diam nisl ultrices et. Pellentesque nulla quis nibh luctus bibendum.</p>",
        education: "<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia in nunc, nec et id viverra tempor consectetur faucibus. Molestie nibh integer diam ac condimentum integer tellus turpis. Mi, varius ullamcorper ultrices arcu sit vel sit. Duis a risus mole.</li></ul>"
    },
    {
        id: 5,
        image: mentor5,
        name: "David Mashiane",
        profile: "Jewelry Design Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>David Mashiane has worked at the coalface of jewelry CAD designer, and jewelry manufacturing for 8 years. Working with manufacturers and retailers of a range of scales, David has mastered the trade of designing and making jewelry, and has achieved a multitude of accolades in recognition of his achievements. In 2008, he won an international fine art painting competition under Motheo FET college. His chandelier designed in 2011 won in a competition at the Thuthuka jewelry awards. Finally, in 2013 David became a finalist in a PPC jewelry competition for a piece he created under the Tshwane University of Technology.</p><p>David is thrilled by the prospect of passing on his immense talent, experience, and skills to Africa's youth.</p>",
        education: "<ul><li>BA in Jewelry Design - Tshwane University of Technology.</li><li>Diploma - Central University of Technology.</li><li>Qualification In Visual Art & N4 Jewelry Design - Motheo College.</li><li>Qualification in Marketing Research and Customer Service from Dumellin College.</li><li>Intro in Web & Graphic Design - Boston College.</li><li>Product CAD Design - design@50.</li><li>Goldsmith Qualification - Shadows Jewelry Company.</li></ul>"
    },
    {
        id: 6,
        image: mentor5,
        name: "David Mashiane",
        profile: "Jewelry Design Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>David Mashiane has worked at the coalface of jewelry CAD designer, and jewelry manufacturing for 8 years. Working with manufacturers and retailers of a range of scales, David has mastered the trade of designing and making jewelry, and has achieved a multitude of accolades in recognition of his achievements. In 2008, he won an international fine art painting competition under Motheo FET college. His chandelier designed in 2011 won in a competition at the Thuthuka jewelry awards. Finally, in 2013 David became a finalist in a PPC jewelry competition for a piece he created under the Tshwane University of Technology.</p><p>David is thrilled by the prospect of passing on his immense talent, experience, and skills to Africa's youth.</p>",
        education: "<ul><li>BA in Jewelry Design - Tshwane University of Technology.</li><li>Diploma - Central University of Technology.</li><li>Qualification In Visual Art & N4 Jewelry Design - Motheo College.</li><li>Qualification in Marketing Research and Customer Service from Dumellin College.</li><li>Intro in Web & Graphic Design - Boston College.</li><li>Product CAD Design - design@50.</li><li>Goldsmith Qualification - Shadows Jewelry Company.</li></ul>"
    },
    {
        id: 7,
        image: mentor4,
        name: "Ty Brooks",
        profile: "Diamond Cutting Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia in nunc, nec et id viverra tempor consectetur faucibus. Molestie nibh integer diam ac condimentum integer tellus turpis. Mi, varius ullamcorper ultrices arcu sit vel sit. Duis a risus molestie dictum vel sit. Tortor fames turpis sed rhoncus ac. Enim, rhoncus, sit quam nunc in lectus. Vel cras libero odio leo nibh phasellus. Scelerisque leo lacus velit et eget a amet at id. At tincidunt adipiscing commodo neque tristique. Odio vitae amet, tristique pulvinar. Tristique diam nisl ultrices et. Pellentesque nulla quis nibh luctus bibendum.</p>",
        education: "<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia in nunc, nec et id viverra tempor consectetur faucibus. Molestie nibh integer diam ac condimentum integer tellus turpis. Mi, varius ullamcorper ultrices arcu sit vel sit. Duis a risus mole.</li></ul>"
    },
    {
        id: 8,
        image: mentor3,
        name: "Mickey Brookshire",
        profile: "Diamond Cutting & Grading Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Mickey began cutting diamonds in 1972. From his small cutting shop in Scottsdale, Arizona he manufactures rough and specializes in repairing damaged stones and recutting older styles to modern proportions. He buys secondhand finished stones as well which are often recut and resold to the trade.</p><p>From 1978 to the present he has been dealing in rough and mining alluvial deposits in the Mano River Union Countries of Sierra Leone, Liberia and Guinea.</p><p>In his efforts to build a more environmentally conscientious and sustainable business in West Africa he now focuses his attention on low-impact dredging and customizing the cutting process. By leaving a small footprint on the environment and maximizing the beauty and value of the rough he has been able to better give back to the people and the communities that have been instrumental to his growth.</p><p>He now mines select alluvial sites and produces signature cuts that respect the individuality of each piece of rough.</p>",
        education: "<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia in nunc, nec et id viverra tempor consectetur faucibus. Molestie nibh integer diam ac condimentum integer tellus turpis. Mi, varius ullamcorper ultrices arcu sit vel sit. Duis a risus mole.</li></ul>"
    },
    {
        id: 9,
        image: mentor2,
        name: "Maarten De Witte",
        profile: "Diamond Cutting & Grading Instructor",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>Maarten de Witte has been intimately involved with cutting gem diamonds for 50 years. Having learned his trade at the American School of Diamond Cutting, he eventually became its Director. He is renowned as the Diamond Wizard™.</p><p>Maarten's experience ranges from the solitary workbench to the global diamond industry. He was instrumental in building the Hearts On Fire Company, from initial start-up to international brand. He is devoted to excellence as a craftsman, innovator, and teacher - in wholesale, retail, branding, training, research and/or development of new cutting styles.</p>",
        education: "<ul><li>Graduate Gemologist - Gemological Institute of America (2003).</li><li>MS in Minerals Geography - University of Illinois (1989).</li><li>BA in African History - University of Illinois (1986).</li><li>Certified Gemologist - American Gem Society (1984).</li></ul>"
    },
    {
        id: 10,
        image: mentor1,
        name: "Fas lebbie",
        profile: "Product designer",
        linkedin: "https://www.linkedin.com",
        email: "example@gmail.com",
        location: "New York, NY, USA",
        intro: "<p>We are led by our Founder Fas Lebbie, an entrepreneur & designer born and raised in Sierra Leone before immigrating to America. He has the experience, education, and purpose to disrupt the natural resource category globally. <span>Root Diamonds</span> has a unique combination of knowledge and passion for the diamond industry with a deep understanding of branding and experiential retail.</p><p>In his Book- <span>Souvenir Of My Awakening</span> recently publish- “ Having been born in the Kono District of Sierra Leone, I've seen first-hand the depravity, corruption and poor conditions the people are living in.</p><p>Currently a Teaching Fellow and Ph.D. Student at <span>Carnegie Mellon University</span>, Fas' research aims at empowering local and natural resource ecosystems and designing sustainable product/service systems (S.PSS) - Specifically Distributed Natural Resources Systems.</p>",
        education: "<ul><li>Currently studying for PhD Transition Design - Carnegie Mellon University (Present).</li><li>MS in Strategic Design and Management - Parsons School of Design: The New School (2021).</li><li>Fellowship Program in Impact Entrepreneurship - Parsons School of Design (2020).</li><li>Certificate in UX Design AR and VR - NYU Tandon School of Engineering (2020).</li><li>BA in Entrepreneurship/Entrepreneurial Studies and International Studies with Concentration in Pre Med - University of Utah (2018).</li></ul>"
    }
];