import './rightPartGateway.css';

import { BsArrowRight } from "react-icons/bs";
import { IoMdQuote } from "react-icons/io";

import { Link } from 'react-router-dom';

import { Markup } from 'interweave';

export default function RightPartGateway(props) {

    return (
        <div className="RightPartGateway">
            <p className='mb-4'>{props.introP}</p>
            <div className='imgs'>
                <img className='img-right' src={props.image2} width="" height="" alt="EPD right" />
                <img className='img-left' src={props.image1} width="" height="" alt="EPD left" />
            </div>
            <div className='quote'>
                <IoMdQuote size={32} />
                <p>{props.quote}</p>
            </div>
            <Markup content={props.textP} />
            <Link to="/register">Register <BsArrowRight size={35} /></Link>
        </div>
    );
}

// props :
// introP - image1 - image2 - quote - textP