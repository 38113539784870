import './bottomPartBlog.css';

import SubscribeForm from '../../../components/form/forms/subscribeForm/subscribeForm.js';

export default function BottomPartBlog() {

    return (
        <div className="BottomPartBlog">
                <h3>SUBSCRIBE TO OUR NEWSELETTER</h3>
                <p className='mt-3'>Subscibe to our newsletter to receive exclusive update and latest news on Design, Entrepreneurship and Manufacturing.</p>
                <p className='mt-3 mb-5'>We promise not to spam you!</p>
                <SubscribeForm />
        </div>
    );
}