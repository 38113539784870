import './about.css';

import BgView from '../../components/layouts/bgView/bgView.js';
import BodyPart from './bodyPart/bodyPart.js';
import FooterPart from './footerPart/footerPart.js';

import bgAbout from '../../assets/images/about/bg.jpg';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from '../../components/layouts/header/header';

export default function About() {
    return (
        <div className="About">
            <Header />
            <BgView img={bgAbout} title="About us" dark={true} imgTitle="Equity centered + Placebase learning" />
            <div className='content'>
                <h5>Beta In Sierra Leone</h5>
                <p>Sierra Leone is one of the largest producers of diamonds in Africa. However, since gaining its independence from the British empire in 1961, it has made little socio-economic progress and suffered from a devastating 11-year-long civil war. During the post-war period, the country continued to be plagued by crises, and the government was unable to provide human development opportunities: education and employment. Representing 80% of the population, young people under the age of 35 are the most severely impoverished. They are the most likely to be employed as mining laborers, earning just $1 a day, but also have the greatest potential to transform Sierra Leone.</p>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <BodyPart />
                    </Col>
                </Row>
            </div>
            <FooterPart />
        </div>
    );
}