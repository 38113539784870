import './partners.css';

import React, { useState } from 'react';

import BgView from '../../components/layouts/bgView/bgView.js';
import ButtonSimple from '../../components/form/inputs/buttonSimple/buttonSimple.js';
import SponsorForm from '../../components/form/forms/sponsorForm/sponsorForm.js';

import bgPartners from '../../assets/images/partners/bg.jpg';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from 'react-bootstrap/Modal';

import { partners } from '../../constants/partners';

import Header from '../../components/layouts/header/header';

export default function Partners() {

    const [lgShow, setLgShow] = useState(false);

    return (
        <div className="Partners">
            <Header />
            <BgView img={bgPartners} title="Partners" dark={false} imgTitle="" />
            <div className='container-list'>
                <p>We are seeking connections with partners who can help us with two aspects of Root Studios. These are the maintenance and running of the incubation center, and connecting our graduates with paid opportunities. Please get in touch if your business can help.</p>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <ul>
                            {partners.map((data) => (
                                <li className='my-1' key={data.id}>
                                    <Row>
                                        <Col xs={4} md={4} lg={4} className='align-self-center p-0'>
                                            <div className='logo d-flex justify-content-center align-items-center'>
                                                <img src={data.image} width='' height='' alt='Partner' />
                                            </div>
                                        </Col>
                                        <Col xs={8} md={8} lg={8} className='align-self-center p-0'>
                                            <h6 >{data.title}</h6>
                                        </Col>
                                    </Row>
                                </li>
                            ))}
                        </ul>
                        <ButtonSimple text='Become a sponsor' link='' click={() => setLgShow(true)} />
                        <Modal size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <SponsorForm />
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </div>
        </div>
    );
}