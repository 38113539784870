import './home.css';
import HomeCarousel from '../../components/layouts/Carousel/Carousel.js';

import Header from '../../components/layouts/header/header';

export default function Home() {
  return (
    <div className="Home">
      <Header />
      <HomeCarousel />
    </div>
  );
}