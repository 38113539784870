import './team.css';

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Markup } from 'interweave';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BgView from '../../components/layouts/bgView/bgView.js';
import bgTeam from '../../assets/images/team/bg.jpg';

import { FaLinkedinIn, FaEnvelope } from "react-icons/fa";

import { PaginatedList } from 'react-paginated-list';

import { mentors } from "../../constants/mentors";

import Header from '../../components/layouts/header/header';

export default function Team() {

    const [selectedMentor, setSelectedMentor] = useState(null);

    return (
        <div className="Team">
            <Header />
            <BgView img={bgTeam} title="Team" dark={true} imgTitle="" />
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <ul className='list-team'>
                        <PaginatedList
                            list={mentors}
                            itemsPerPage={5}
                            nextText='Next'
                            prevText='Prev.'
                            loopAround={false}
                            displayNumbers={true}
                            displayRange={1}
                            isLoading={false}
                            renderList={(list) => (
                                <>
                                    {list.map((data) => {
                                        return (
                                            <li key={data.id} className='member-team'>
                                                <Row>
                                                    <Col xs={2} md={2} lg={2} className='part-1'>
                                                        <img src={data.image} width="" height="" alt='Mentor' />
                                                    </Col>
                                                    <Col xs={10} md={10} lg={10}>
                                                        <Row>
                                                            <Col xs={12} md={12} lg={12} className='part-2'>
                                                                <h6> {data.name} </h6>
                                                                <p> {data.profile} </p>
                                                                <div className='social d-inline-flex'>
                                                                    <a href={data.linkedin} target='_blanck'><FaLinkedinIn size={15} /></a>
                                                                    <a href={`mailto:${data.email}`}><FaEnvelope size={15} /></a>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={12} lg={12} className='part-3 d-flex justify-content-between align-items-end'>
                                                                <p className='more-infos' onClick={() => setSelectedMentor(data)}> Learn More </p>
                                                                <p> {data.location} </p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </li>
                                        );
                                    })}
                                </>
                            )}
                        />
                    </ul>
                </Col>
            </Row>
            <Modal size="lg"
                show={selectedMentor}
                onHide={() => setSelectedMentor(null)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} md={12} lg={5} xl={5} className='picture-part p-0'>
                            <img src={selectedMentor?.image} width="" height="" alt='Mentor' className='mb-4' />
                        </Col>
                        <Col sm={12} md={12} lg={7} xl={7} className='infos-part'>
                            <h4>{selectedMentor?.name}</h4>
                            <Markup content={selectedMentor?.intro} className='intro-member' />
                            <h6>Education & Specialty</h6>
                            <Markup content={selectedMentor?.education} className='education-member' />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
}