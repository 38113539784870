import './innerBlog.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BgView from '../../components/layouts/bgView/bgView';
import bgBlog from '../../assets/images/blogs/bg.jpg';

import { useParams as params } from "react-router-dom";
import { blogs } from "../../constants/blogs";

// Page parts
import LeftPart from './leftPartBlog/leftPartBlog';
import RightPart from './rightPartBlog/rightPartBlog';
import BottomPart from './bottomPartBlog/bottomPartBlog';

import Header from '../../components/layouts/header/header';

export default function InnerBlog() {

    const { blog_id } = params();
    const blogFiltered = blogs.find(blog => String(blog.id) === blog_id);

    const currentUrl = window.location.href;

    return (
        <div className="InnerBlog">
            <Header />
            <BgView img={bgBlog} title="Our blogs" dark={false} imgTitle="" />
            <Row>
                <Col xs={12} md={12} lg={3}>
                    <LeftPart blogger={blogFiltered.blogger} publishDate={blogFiltered.date} featuredBlog={blogs} sharedURL={currentUrl} />
                </Col>
                <Col xs={12} md={12} lg={9}>
                    <RightPart quote={blogFiltered.more.right.quote} title={blogFiltered.title} introP={blogFiltered.more.right.introP} image1={blogFiltered.more.right.image1} image2={blogFiltered.more.right.image2} textP={blogFiltered.more.right.textP}/>
                </Col>
            </Row>
            <BottomPart />
        </div>
    );
}