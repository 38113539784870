import './leftPartGraduate.css';

export default function LeftPartGraduate(props) {

    return (
        <div className='LeftPartGraduate'>
            <div className='sdgs'>
                <h6>SDGs</h6>
                <p>This teaching activity contributes to the achievement of the Sustainable Development Goals of the UN 2030 Agenda.</p>
                <hr />
                <div className='sdg-imgs'>
                    {props.sdgs.map((data) => (
                        <img key={data.id} src={data.image} width="" height="" alt="sdg" />
                    ))}
                </div>
            </div>
        </div>
    );
}

// props :
// trainer - sdgs