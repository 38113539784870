export const contact = {
    email: "info@rootstudios.com",
    addr1: "500 terry francois street",
    addr2: "San franco, ca 94158",
    addrLink: "https://goo.gl/maps/KoCK2CtufdUgfkxCA",
    phone: "123-456-7890",
    openingHours: [
        "Man - Fri: 8am - 8pm",
        "Saturday: 9am - 7pm",
        "Sunday: 9am - 8pm"
    ]
}