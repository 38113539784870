// Items files importations
import graduate1 from '../assets/images/graduates/graduate-1.jpg';
import graduate2 from '../assets/images/graduates/graduate-2.jpg';
import graduate3 from '../assets/images/graduates/graduate-3.jpg';
import graduate4 from '../assets/images/graduates/graduate-4.jpg';
import graduate5 from '../assets/images/graduates/graduate-5.jpg';

// import images of graduates detail
// graduate-1
import graduate1_1 from '../assets/images/posts/posts_detail/post-5/img-1.jpg';
import graduate1_2 from '../assets/images/posts/posts_detail/post-5/img-2.jpg';
// graduate-2
import graduate2_1 from '../assets/images/posts/posts_detail/post-1/img-1.jpg';
import graduate2_2 from '../assets/images/posts/posts_detail/post-1/img-2.jpg';
// graduate-3
import graduate3_1 from '../assets/images/posts/posts_detail/post-2/img-1.jpg';
import graduate3_2 from '../assets/images/posts/posts_detail/post-2/img-2.jpg';
// graduate-4
import graduate4_1 from '../assets/images/posts/posts_detail/post-3/img-1.jpg';
import graduate4_2 from '../assets/images/posts/posts_detail/post-3/img-2.jpg';
// graduate-5
import graduate5_1 from '../assets/images/posts/posts_detail/post-4/img-1.jpg';
import graduate5_2 from '../assets/images/posts/posts_detail/post-4/img-2.jpg';


// import SDGs images
import sdg_n1 from '../assets/images/posts/posts_detail/sdgs/sdg-1.png';
import sdg_n4 from '../assets/images/posts/posts_detail/sdgs/sdg-2.png';
import sdg_n8 from '../assets/images/posts/posts_detail/sdgs/sdg-3.png';
import sdg_n9 from '../assets/images/posts/posts_detail/sdgs/sdg-4.png';
import sdg_n10 from '../assets/images/posts/posts_detail/sdgs/sdg-5.png';
import sdg_n11 from '../assets/images/posts/posts_detail/sdgs/sdg-6.png';
import sdg_n12 from '../assets/images/posts/posts_detail/sdgs/sdg-7.png';

export const graduates = [
    {
        id: 1,
        image: graduate1,
        title: "JEWELRY DESIGN",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n4,
                    },
                    {
                        id: 2,
                        image: sdg_n12,
                    }
                ]
            },
            right: {
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field.</p>",
                image1: graduate1_1,
                image2: graduate1_2,
                quote: "Fames urna pulvinar vitae fusce in lacus amet imperdiet viverra. Cursus sed nunc, eu luctus sapien nisl egestas elit mattis. In diam egestas sed et ac venenatis lectus. Egestas blandit varius ipsum justo, tellus mauris sed. Id enim amet convallis dis laoreet at risus neque. Sit venenatis egestas ultrices dapibus amet curabitur. Urna, est mauris tellus sem amet enim at sed. Pharetra, in volutpat aenean ultrices. Eu nunc duis neque dolor, ante natoque. Cursus volutpat bibendum consequat massa pharetra. Nunc, neque, in aliquam venenatis.",
            },
            bottom: {
                career: {
                    textP: "These skills will be applicable in real-life work situations, and graduates will be able to use their portfolio formed during the course to access opportunities within the jewelry industry.",
                    careerList: [
                        "Jewelry designer",
                        "Jewelry seller",
                        "CAD technician",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum"
                    ],
                },
                cohortList: [
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                ]
            }
        }
    },
    {
        id: 2,
        image: graduate2,
        title: "DIAMOND CUTTing",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n1,
                    },
                    {
                        id: 2,
                        image: sdg_n4,
                    },
                    {
                        id: 3,
                        image: sdg_n8,
                    },
                    {
                        id: 4,
                        image: sdg_n9,
                    },
                    {
                        id: 5,
                        image: sdg_n10,
                    }
                ]
            },
            right: {
                introP: "<p>Root Studios will open a diamond cutting shop where graduates can teach new cutters. Graduates may also seek commissions from local artisanal miners, who may choose to pay graduates in the shop to cut their diamonds helping the artisanal miner to achieve a better value for their diamonds..</p><p>Graduates will also have access to laptops and the internet. They may deploy their entrepreneurial skills to create their own diamond brands, giving them the ability to sell gems on the international market.</p>",
                image1: graduate2_1,
                image2: graduate2_2,
                quote: "Fames urna pulvinar vitae fusce in lacus amet imperdiet viverra. Cursus sed nunc, eu luctus sapien nisl egestas elit mattis. In diam egestas sed et ac venenatis lectus. Egestas blandit varius ipsum justo, tellus mauris sed. Id enim amet convallis dis laoreet at risus neque. Sit venenatis egestas ultrices dapibus amet curabitur. Urna, est mauris tellus sem amet enim at sed. Pharetra, in volutpat aenean ultrices. Eu nunc duis neque dolor, ante natoque. Cursus volutpat bibendum consequat massa pharetra. Nunc, neque, in aliquam venenatis.",
            },
            bottom: {
                career: {
                    textP: "Finally, cutting graduates may use their gained knowledge of the diamond mining industry to access management positions within diamond companies that local people have historically been excluded from.",
                    careerList: [
                        "Diamond cutter",
                        "Manufacturer",
                        "Machine operator",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum"
                    ],
                },
                cohortList: [
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                ]
            }
        }
    },
    {
        id: 3,
        image: graduate3,
        title: "UI/UX DESIGN",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n4,
                    },
                    {
                        id: 2,
                        image: sdg_n8,
                    }
                ]
            },
            right: {
                introP: "<p>Digital design graduates of Root Studios will be given access to laptops and the internet, from which they may compete for work on freelancing platforms such as BeHance, Upwork, and PeoplePerHour.</p><p>Graduates may also apply for opportunities within Root Studios, teaching design and entrepreneurship skills to future intakes.</p>",
                image1: graduate3_1,
                image2: graduate3_2,
                quote: "Fames urna pulvinar vitae fusce in lacus amet imperdiet viverra. Cursus sed nunc, eu luctus sapien nisl egestas elit mattis. In diam egestas sed et ac venenatis lectus. Egestas blandit varius ipsum justo, tellus mauris sed. Id enim amet convallis dis laoreet at risus neque. Sit venenatis egestas ultrices dapibus amet curabitur. Urna, est mauris tellus sem amet enim at sed. Pharetra, in volutpat aenean ultrices. Eu nunc duis neque dolor, ante natoque. Cursus volutpat bibendum consequat massa pharetra. Nunc, neque, in aliquam venenatis."
            },
            bottom: {
                career: {
                    textP: "Finally, they may apply for design opportunities within our partner organizations.",
                    careerList: [
                        "UX Designer",
                        "UI Designer",
                        "Design Researcher",
                        "Information Architect",
                        "Usability Tester",
                        "Analyst",
                        "Product Designer: Digital",
                        "Lorem ipsum"
                    ],
                },
                cohortList: [
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                ]
            }
        }
    },
    {
        id: 4,
        image: graduate4,
        title: "MINERALS DESIGN",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n4,
                    },
                    {
                        id: 2,
                        image: sdg_n9,
                    },
                    {
                        id: 3,
                        image: sdg_n11,
                    },
                    {
                        id: 4,
                        image: sdg_n12,
                    }
                ]
            },
            right: {
                introP: "<p>Material produce design graduates may use Root Studios' facilities to continue to design for their communities unique needs by experimenting with the alternative uses for the resources found in situ.</p>",
                image1: graduate4_1,
                image2: graduate4_2,
                quote: "Fames urna pulvinar vitae fusce in lacus amet imperdiet viverra. Cursus sed nunc, eu luctus sapien nisl egestas elit mattis. In diam egestas sed et ac venenatis lectus. Egestas blandit varius ipsum justo, tellus mauris sed. Id enim amet convallis dis laoreet at risus neque. Sit venenatis egestas ultrices dapibus amet curabitur. Urna, est mauris tellus sem amet enim at sed. Pharetra, in volutpat aenean ultrices. Eu nunc duis neque dolor, ante natoque. Cursus volutpat bibendum consequat massa pharetra. Nunc, neque, in aliquam venenatis.",
            },
            bottom: {
                career: {
                    textP: "Finally, cutting graduates may use their gained knowledge of Mineral Design industry to access management positions within industries.",
                    careerList: [
                        "Product Designer: Material",
                        "Manufacturer",
                        "Design Entrepreneurn",
                        "Information Architect",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum"
                    ],
                },
                cohortList: [
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                ]
            }
        }
    },
    {
        id: 5,
        image: graduate5,
        title: "ENTREPRENEURSHIP",
        more: {
            left: {
                sdgs: [
                    {
                        id: 1,
                        image: sdg_n1,
                    },
                    {
                        id: 2,
                        image: sdg_n4,
                    },
                    {
                        id: 3,
                        image: sdg_n8,
                    },
                    {
                        id: 4,
                        image: sdg_n9,
                    },
                    {
                        id: 5,
                        image: sdg_n11,
                    }
                ]
            },
            right: {
                introP: "<p>Graduates will be empowered to leverage their new skills to begin place-based and eco-friendly enterprises within their communities, or within international marketplaces via online platforms. To help graduates to launch these enterprises, Root Studios will offer grants and funding that alumni will be invited to pitch for.</p>",
                image1: graduate5_1,
                image2: graduate5_2,
                quote: "Fames urna pulvinar vitae fusce in lacus amet imperdiet viverra. Cursus sed nunc, eu luctus sapien nisl egestas elit mattis. In diam egestas sed et ac venenatis lectus. Egestas blandit varius ipsum justo, tellus mauris sed. Id enim amet convallis dis laoreet at risus neque. Sit venenatis egestas ultrices dapibus amet curabitur. Urna, est mauris tellus sem amet enim at sed. Pharetra, in volutpat aenean ultrices. Eu nunc duis neque dolor, ante natoque. Cursus volutpat bibendum consequat massa pharetra. Nunc, neque, in aliquam venenatis.",
            },
            bottom: {
                career: {
                    textP: "Finally, they may applywithin our partner organizations.",
                    careerList: [
                        "Entrepreneur",
                        "Budget Analyst",
                        "Financial Manager",
                        "HR Manager",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum",
                        "Lorem ipsum"
                    ],
                },
                cohortList: [
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                    "fas lebbie",
                ]
            }
        }
    }
];