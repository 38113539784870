import '../forms.css';

import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import ButtonSubmit from '../../inputs/buttonSubmit/buttonSubmit.js';
import Select from "react-select";

export default function RegisterForm() {

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const options = [
    { value: "product_design", label: "Product design" },
    { value: "diamond_cutting", label: "Diamond Cutting" },
    { value: "jewelry_design", label: "Jewelry Design" },
    { value: "entrepreneurship", label: "Entrepreneurship" }
  ];

  return (
    <div className="RegisterForm">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mt-2 mb-4">
          <Form.Group as={Col} xs="6" sm="6" md="6" xl="6" lg="6" controlId="validation01">
            <Form.Control
              type="text"
              placeholder="First name"
              pattern="[A-Za-z]{1,}"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid first name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs="6" sm="6" md="6" xl="6" lg="6" controlId="validation02">
            <Form.Control
              type="text"
              placeholder="Last name"
              pattern="[A-Za-z]{1,}"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid last name.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="my-4">
          <Form.Group as={Col} xs="6" sm="6" md="6" xl="6" lg="6" controlId="validation03">
            <Form.Control
              required
              type="email"
              placeholder="Email *"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs="6" sm="6" md="6" xl="6" lg="6" controlId="validation04">
            <Select
              className='select-input'
              classNamePrefix="select"
              options={options}
              isSearchable={false}
              isClearable={true}
              placeholder="Select ..."
              required
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid phone.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="my-5">
          <Form.Group as={Col} xs="12" sm="12" md="12" xl="12" lg="12" controlId="validation05">
            <Form.Control
              as="textarea"
              placeholder="Message"
              pattern="[A-Za-z0-9]{5,}"
              rows="1"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid message.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <ButtonSubmit text="Submit" />
      </Form>
    </div>
  );
}