import './footerPart.css';

import Img1 from '../../../assets/images/about/method-1.jpg';
import Img2 from '../../../assets/images/about/method-2.jpg';

export default function FooterPart() {
    return (
        <div className="FooterPart">
            <h3>Our method</h3>
            <div className='imgs'>
                <img src={Img1} width='' height='' alt='Method 1'/>
                <img src={Img2} width='' height='' alt='Method 2'/>
            </div>
            <p className='mt-3'>Root Studios follows the “Learning Portfolio” teaching methodology pioneered by The New School in New York. Enrolled in practice-based job-ready programs, students create a portfolio of work while completing their courses that can be used as a display of expertise to access paid opportunities after graduation. Students will be encouraged to consider the contextual uniqueness of their communities, so that the artifacts that they create for their portfolio will simultaneously have utility for people in their locality.</p>
            <p className='mt-3'>In the classroom, we understand the barriers to understanding that emerge from students' background and upbringing. Mitigating this, we deploy equity-based communication which ensures our teaching is culturally-appropriate and sensitive to neurodiversity or disability.</p>
        </div>
    );
}