import './intro.css';
import ButtonSimple from '../../components/form/inputs/buttonSimple/buttonSimple.js';

import intro_bg from '../../assets/images/intro_page/bg.png';
import intro_card_border from '../../assets/images/intro_page/border.png';

export default function Intro() {
  return (
    <div className="Intro">
      <div className='bg'>
        <img className='bg-intro' src={intro_bg} width="" height="" alt="bg" />
      </div>
      <div className="content">
        <div className="intro-card">
          <img className='img-border' src={intro_card_border} width="" height="" alt="border" />
          <div className='d-flex flex-column justify-content-between align-items-between'>
            <div className='logo'>
              <p>Root</p>
              <span>studios</span>
            </div>
            <ul>
              <li>Design</li>
              <li>Entrepreneurship</li>
              <li>Manufacturing</li>
            </ul>
          </div>
        </div>
        <p className='mb-5'>Empowering youth to sustenance by building centers of excellence that foster human capital development for diversified local and international opportunities</p>
        <ButtonSimple text='ENTER OUR STUDIOS' link='/home' />
      </div>
    </div>
  );
}