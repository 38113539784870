// Items files importations
import blog1 from '../assets/images/blogs/blog-1.jpg';
import blog2 from '../assets/images/blogs/blog-2.jpg';
import blog3 from '../assets/images/blogs/blog-3.jpg';
import blog4 from '../assets/images/blogs/blog-4.jpg';

export const blogs = [
    {
        id: 1,
        image: blog1,
        title: "Blog title",
        blogger: "by fas lebbie",
        introText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sits dolor.",
        date: "22-05-2022",
        more: {
            right: {
                quote: "Blog Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field.</p>",
                image1: blog1,
                image2: blog1,
                textP: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p>"
            },
        }
    },
    {
        id: 2,
        image: blog2,
        title: "Blog title",
        blogger: "by fas lebbie",
        introText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sits dolor.",
        date: "22-05-2022",
        more: {
            right: {
                quote: "Blog Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field.</p>",
                image1: blog2,
                image2: blog2,
                textP: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p>"
            },
        }
    },
    {
        id: 3,
        image: blog3,
        title: "Blog title",
        blogger: "by fas lebbie",
        introText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sits dolor.",
        date: "22-05-2022",
        more: {
            right: {
                quote: "Blog Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field.</p>",
                image1: blog3,
                image2: blog3,
                textP: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p>"
            },
        }
    },
    {
        id: 4,
        image: blog4,
        title: "Blog title",
        blogger: "by fas lebbie",
        introText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sits dolor.",
        date: "22-05-2022",
        more: {
            right: {
                quote: "Blog Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field.</p>",
                image1: blog4,
                image2: blog4,
                textP: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p>"
            },
        }
    },
    {
        id: 4,
        image: blog4,
        title: "Blog title",
        blogger: "by fas lebbie",
        introText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sits dolor.",
        date: "22-05-2022",
        more: {
            right: {
                quote: "Blog Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field.</p>",
                image1: blog4,
                image2: blog4,
                textP: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p>"
            },
        }
    },
    {
        id: 1,
        image: blog1,
        title: "Blog title",
        blogger: "by fas lebbie",
        introText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sits dolor.",
        date: "22-05-2022",
        more: {
            right: {
                quote: "Blog Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field.</p>",
                image1: blog1,
                image2: blog1,
                textP: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p>"
            },
        }
    },
    {
        id: 2,
        image: blog2,
        title: "Blog title",
        blogger: "by fas lebbie",
        introText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sits dolor.",
        date: "22-05-2022",
        more: {
            right: {
                quote: "Blog Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field.</p>",
                image1: blog2,
                image2: blog2,
                textP: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p>"
            },
        }
    },
    {
        id: 3,
        image: blog3,
        title: "Blog title",
        blogger: "by fas lebbie",
        introText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sit s dolor. Malesuada sapien vulputate sit a sits dolor.",
        date: "22-05-2022",
        more: {
            right: {
                quote: "Blog Title - Lorem ipsum dolor sit amet, consectetur adipiscing elit",
                introP: "<p>Graduates of the jewelry design course will possess a strong understanding of jewelry design and manufacturing methods, techniques, and technology. Furthermore, they will have the research skills required to enter as a specialist into any design or technology within the jewelry design field.</p>",
                image1: blog3,
                image2: blog3,
                textP: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo urna, adipiscing sed egestas dolor. Malesuada sapien vulputate sit a sit. Tristique mollis pellentesque sit congue pulvinar. Orci mi mus integer eu, posuere auctor orci ridiculus viverra. Aenean et sed lectus ac sed amet tortor. Ultricies faucibus mauris egestas enim vulputate lorem ultricies tellus felis. Elementum sit rutrum enim nec ac augue pellentesque.</p>"
            },
        }
    }
];