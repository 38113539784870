import './bottomPartGateway.css';
import ButtonSimple from '../../../components/form/inputs/buttonSimple/buttonSimple.js';

import { Link } from "react-router-dom";

export default function BottomPartGateway(props) {

    return (
        <div className="BottomPartGateway">
            <div className='donation'>
                <h3>Make a donation</h3>
                <p>At Root Studios, we're committed to providing life-changing education and opportunities for young people across Africa. All charitable donations made via the link below go directly towards helping disadvantaged youth's realize their full potential in Fourth Industrial Revolution industries.</p>
                <p className='mt-3 mb-5'>Your generosity frees youths from entrapment in extractive industries which damages their health, environment, and community. None of this is possible without your help. Give money today and help us change the lives of those in need.</p>
                <ButtonSimple text='Make a donation' link='/' />
            </div>
            <div className='next-post'>
                <p>CONTINUE TO SCROLL AND</p>
                <Link to={`/gateway/${props.idNext}`} replace>
                    <p>READ ABOUT THE NEXT CLASS</p>
                </Link>
                <div className='post'>
                    <Link to={`/gateway/${props.idNext}`} replace>
                        <img src={props.imageNext} width="" height="" alt="Post" />
                        <h1>{props.titleNext}</h1>
                    </Link>
                </div>
            </div>
        </div >
    );
}

// props :
// idNext - imageNext - titleNext