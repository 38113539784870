import './graduate.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useParams as params } from "react-router-dom";
import { graduates } from "../../constants/graduates";

// Page parts
import TopPart from './topPartGraduate/topPartGraduate';
import LeftPart from './leftPartGraduate/leftPartGraduate';
import RightPart from './rightPartGraduate/rightPartGraduate';
import BottomPart from './bottomPartGraduate/bottomPartGraduate';

import Header from '../../components/layouts/header/header';

export default function Graduate() {

    const { graduate_id } = params();
    const graduateFiltered = graduates.find(graduate => String(graduate.id) === graduate_id);

    return (
        <div className="Graduate">
            <Header />
            <TopPart image={graduateFiltered.image} title={graduateFiltered.title} />

            <Row>
                <Col xs={12} md={12} lg={3}>
                    <LeftPart trainer={graduateFiltered.more.left.trainer} sdgs={graduateFiltered?.more?.left?.sdgs} />
                </Col>
                <Col xs={12} md={12} lg={9}>
                    <RightPart introP={graduateFiltered.more.right.introP} image1={graduateFiltered.more.right.image1} image2={graduateFiltered.more.right.image2} quote={graduateFiltered.more.right.quote} textP={graduateFiltered.more.right.textP} />
                </Col>
            </Row>
            <BottomPart careerP={graduateFiltered.more.bottom.career.textP} careerList={graduateFiltered.more.bottom.career.careerList} cohortList={graduateFiltered.more.bottom.cohortList} />
        </div>
    );
}