import '../forms.css';

import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import ButtonSubmit from '../../inputs/buttonSubmit/buttonSubmit.js';

export default function SubscribeForm() {

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="SubscribeForm">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="my-4">
          <Form.Group as={Col} xs="12" sm="12" md="12" xl="12" lg="12" controlId="validation02">
            <Form.Control
              required
              type="email"
              placeholder="Email address"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter a valid email.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <ButtonSubmit text="Subscribe" />
      </Form>
    </div>
  );
}