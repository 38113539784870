import './rightPartBlog.css';

import { IoMdQuote } from "react-icons/io";
import { Markup } from 'interweave';

export default function RightPartBlog(props) {

    return (
        <div className="RightPartBlog">
            <div className='quote'>
                <IoMdQuote size={16} />
                <p>{props.quote}</p>
            </div>
            <h5>{props.title}</h5>
            <Markup content={props.introP} />
            <div className='imgs'>
                <img className='img-right' src={props.image1} width="" height="" alt="EPD right" />
                <img className='img-left' src={props.image2} width="" height="" alt="EPD left" />
            </div>
            <Markup content={props.textP} />
        </div>
    );
}

// props :
// quote - title - introP - image1 - image2 - textP