import './topPartGraduate.css';
import BgView from '../../../components/layouts/bgView/bgView';

export default function TopPartGraduate(props) {
    return (
        <div className="TopPartGraduate">
            <BgView img={props.image} title={props.title} dark={false} imgTitle="" />
        </div>
    );
}

// props :
// image - title