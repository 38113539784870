import './header.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../../assets/images/logo.png';
import { Sling as Hamburger } from 'hamburger-react';
import { FaLinkedinIn, FaTwitter, FaFacebookSquare, FaYoutube } from "react-icons/fa";

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Link, NavLink } from 'react-router-dom';

export default function Header() {

  const [isOpen, setOpen] = useState(false);
  const handleCloseIcon = () => setShow(false);

  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const handleClose = () => setShow(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <div className="Header">
      <Navbar>
        <Container>
          <Navbar.Collapse>
            <Navbar.Brand>
              <Link to="/home" replace>
                <img src={logo} width="" height="" alt="Logo" />
              </Link>
              <Hamburger rounded size={26} toggled={isOpen} toggle={setOpen} direction="left" duration={0.6} color="#6B6B6B" onToggle={toggled => {
                if (toggled) {
                  handleShow();
                } else {
                  handleClose();
                }
              }} />
            </Navbar.Brand>
            <Navbar.Text className='align-self-center'>
              <div className='title'>
                <h6>Sustainable empowerment for African youth</h6>
                <p className="mt-3">Open Collaborative Co-Working Spaces to empower and inspire young Africans</p>
              </div>
              <Hamburger rounded size={26} toggled={isOpen} toggle={setOpen} direction="left" duration={0.6} color="#6B6B6B" onToggle={toggled => {
                if (toggled) {
                  handleShow();
                } else {
                  handleClose();
                }
              }} />
            </Navbar.Text>
            <Navbar.Text>
              <Link to='/register' onClick={[handleClose, handleCloseIcon]} replace>Register</Link>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
        <Modal show={show} onHide={handleClose} fullscreen={fullscreen} className="overlay-menu">
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <ul className='d-flex flex-column justify-content-between'>
              <NavLink to="/home" onClick={[handleClose, handleCloseIcon]} replace><li>Home</li></NavLink>
              <NavLink to="/about" onClick={[handleClose, handleCloseIcon]} replace><li>About us</li></NavLink>
              <NavLink to="/team" onClick={[handleClose, handleCloseIcon]} replace><li>Team</li></NavLink>
              <NavLink to="/contact" onClick={[handleClose, handleCloseIcon]} replace><li>Contact</li></NavLink>
              <NavLink to="/partners" onClick={[handleClose, handleCloseIcon]} replace><li>Partners</li></NavLink>
              <NavLink to="/blogs" onClick={[handleClose, handleCloseIcon]} replace><li>Blogs</li></NavLink>
              <NavLink to="/graduates" onClick={[handleClose, handleCloseIcon]} replace><li>Our Graduates</li></NavLink>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Link to='/register' onClick={[handleClose, handleCloseIcon]} replace>Register</Link>
            <p>&copy; {(new Date().getFullYear())} Root Studio, All Right Reserved</p>
            <div className='social-media'>
              <a href='https://www.linkedin.com' target='_blanck'><FaLinkedinIn size={18} /></a>
              <a href='https://www.twitter.com' target='_blanck'><FaTwitter size={18} /></a>
              <a href='https://www.facebook.com' target='_blanck'><FaFacebookSquare size={18} /></a>
              <a href='https://www.youtube.com' target='_blanck'><FaYoutube size={18} /></a>
            </div>
          </Modal.Footer>
        </Modal>
      </Navbar>
    </div>
  );
}