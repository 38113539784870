import './error404.css';

import ButtonSimple from '../../../components/form/inputs/buttonSimple/buttonSimple.js';

import Header from '../../../components/layouts/header/header';

export default function Error404() {
    return (
        <div className="Error404">
            <Header />
            <h1>404</h1>
            <h6>Page Not Found</h6>
            <p>The page you are looking for does not seems to exist</p>
            <ButtonSimple text='Go back' link='/home' click='' />
        </div>
    );
}