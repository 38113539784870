import './contact.css';

import BgView from '../../components/layouts/bgView/bgView.js';
import ContactForm from '../../components/form/forms/contactForm/contactForm.js';

import bgContact from '../../assets/images/contact/bg.jpg';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { contact } from '../../constants/contactInfos.js';

import Header from '../../components/layouts/header/header';

export default function Contact() {
    return (
        <div className="Contact">
            <Header />
            <BgView img={bgContact} title="Contact us" dark={false} imgTitle="" />
            <Row className='content'>
                <Col xs={12} sm={12} md={12} xl={7} lg={7} className='infos'>
                    <h4>Root studios</h4>
                    <ul className='coordinates'>
                        <li><a href={`mailto:${contact.email}`}>{contact.email}</a></li>
                        <li>
                            <a href={contact.addrLink} target="_blanck">{contact.addr1}<br />{contact.addr2}</a>
                        </li>
                        <li><a href={`tel:${contact.phone}`}>{contact.phone}</a></li>
                    </ul>
                    <h5>Opening hours</h5>
                    <ul className='opening-hours'>
                        {contact.openingHours.map((data, idx) => (
                            <li key={idx}>
                                {data}
                            </li>
                        ))}
                    </ul>
                </Col>
                <Col xs={12} sm={12} md={12} xl={5} lg={5} className='form'>
                    <h4>Send us a mail</h4>
                    <ContactForm />
                </Col>
            </Row>
        </div>
    );
}