import './Carousel.css';
import { Link } from "react-router-dom";

// Swiper importations
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Keyboard, Mousewheel } from 'swiper';
import 'swiper/css';
import "swiper/css/navigation";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Video player importations
import "../../../../node_modules/video-react/dist/video-react.css";
import { Player } from 'video-react';

import video from '../../../assets/videos/video.mp4';
import posterVideo from '../../../assets/images/intro_page/video-poster.png';
import { posts } from '../../../constants/posts.js';

export default function HomeCarousel() {
    SwiperCore.use([Navigation, Keyboard, Mousewheel]);

    return (
        <div className="Carousel">
            <Swiper
                className='main-swiper'
                // rebuildOnUpdate={true}
                spaceBetween={310}
                slidesPerView="auto"
                slidesPerGroup={1}
                loop={false}
                autoplay={false}
                direction="horizontal"
                keyboard={true}
                mousewheel={true}
                centeredSlides={true}
                slideToClickedSlide={true}
                modules={[Navigation, Mousewheel, Keyboard]}
                navigation={{
                    prevEl: '.prev',
                    nextEl: '.next',
                    clickable: true,
                    hideOnClick: true,
                    disabledClass: 'disabled',
                }}
                breakpoints={{
                    768: {
                        slidePerView: 1,
                        spaceBetween: 180,
                    },
                    992: {
                        slidePerView: 1,
                        spaceBetween: 360,
                    },
                    1200: {
                        slidePerView: 1,
                        spaceBetween: 320,
                    },
                    2000: {
                        slidePerView: 1,
                        spaceBetween: 450,
                    },
                    2500: {
                        slidePerView: 1,
                        spaceBetween: 600,
                    },
                    2800: {
                        slidePerView: 1,
                        spaceBetween: 900,
                    },
                    5000: {
                        slidePerView: 1,
                        spaceBetween: 1200,
                    }
                }}
            >
                <SwiperSlide>
                    <Row className='h-100'>
                        <Col xs={12} sm={12} md={12} xl={6} lg={6} className='content'>
                            <h1 className='title'>Co-working Space</h1>
                            <h3 className='subTitle'>Design Hub & Entrepreneurship Incubator</h3>
                            <h3 className='subTitle-mobile'>Design Hub And Incubator</h3>
                            <div className='video'>
                                <Player playsInline poster={posterVideo} src={video} />
                            </div>
                            <p className='mt-4'>Training and developing the design responsive leaders of the future by teaching and unlocking opportunities in design, entrepreneurship & manufacturing that will improve the lives of youth in poor & vulnerable communities. Helping them to intervene in their communities, according to its contextual uniqueness.</p>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={6} lg={6} className='links'>
                            <ul>
                                {posts.map((data, idx) => (
                                    <li className='my-1' key={idx}>
                                        <Link to={`/gateway/${data.id}`}>{data.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </SwiperSlide>
                {posts.map((data) => (
                    <SwiperSlide key={data.id}>
                        <LazyLoadImage
                            alt="Post"
                            src={data.image}
                            effect="blur"
                            placeholderSrc="blur"
                            threshold={200}
                        />
                        <h1 className="title">{data.title}</h1>
                        <p className="details">{data.description}
                            <Link to={`/gateway/${data.id}`}>Learn more</Link>
                        </p>
                    </SwiperSlide>
                ))}
                <div className='myNavigation'>
                    <BsArrowLeft className='prev' />
                    <BsArrowRight className='next' />
                </div>
            </Swiper>
        </div>
    )
}