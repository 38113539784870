import './blogs.css';

import { Link } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BgView from '../../components/layouts/bgView/bgView.js';
import bgBlog from '../../assets/images/blogs/bg.jpg';

import { PaginatedList } from 'react-paginated-list';

import { blogs } from "../../constants/blogs";

import Header from '../../components/layouts/header/header';

export default function Blogs() {
    return (
        <div className="Blogs">
            <Header />
            <BgView img={bgBlog} title="Our Blogs" dark={false} imgTitle="" />
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <p className='text-center mb-5'>Keep up with Root Studios latest work by reading our blogs.</p>
                    <ul className='list-blogs'>
                        <PaginatedList
                            list={blogs}
                            itemsPerPage={4}
                            nextText='Next'
                            prevText='Prev.'
                            loopAround={false}
                            displayNumbers={true}
                            displayRange={1}
                            isLoading={false}
                            renderList={(list) => (
                                <>
                                    {list.map((data) => {
                                        return (
                                            <li key={data.id} className='item-blog'>
                                                <Row>
                                                    <Col sm={12} md={2} lg={2} xl={2} className='part-1'>
                                                        <img src={data.image} width="" height="" alt='Blog' />
                                                    </Col>
                                                    <Col sm={12} md={10} lg={10} xl={10} className='part-2'>
                                                        <Row>
                                                            <Col xs={12} md={12} lg={12} className='title'>
                                                                <h6> {data.title} </h6>
                                                                <p> {data.blogger} </p>
                                                            </Col>
                                                            <Col xs={12} md={12} lg={12} className='infos'>
                                                                <Row>
                                                                    <Col sm={12} md={2} lg={10} xl={10}>
                                                                        <p className='more-infos'>
                                                                            {data.introText}
                                                                            <Link to={`/blog/${data.id}`}>read more</Link>
                                                                        </p>
                                                                    </Col>
                                                                    <Col sm={12} md={2} lg={2} xl={2} className='align-self-end'>
                                                                        <p className='date'>{data.date}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </li>
                                        );
                                    })}
                                </>
                            )}
                        />
                    </ul>
                </Col>
            </Row>
        </div>
    );
}